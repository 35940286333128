import React from "react";
import { graphql, Link } from "gatsby";
import styled, { css, createGlobalStyle } from "styled-components/macro";
import Trigger from "../components/trigger";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import Footer from "../components/footer";
import { NormalizeCSS } from "../utils";
import Favicon from "../components/favicon";
import { Menu } from "../components/menu";
import { useSitemap } from "../components/sitemap";
import Img from "gatsby-image";

const GlobalStyles = createGlobalStyle`
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  background: #ffffff;
}
h2, h3, h4 {
  font-weight: 300;
}
h3 {
  font-size: 42px;
  line-height: 52px;
  margin: 0;
}
p {
  font-size: 18px;
  line-height: 28px;
}
a {
  text-decoration: none;
  color: #00e;
}
`;

const Container = styled.div<{ width?: number }>`
  max-width: 1176px;
  ${({ width }) =>
    typeof width !== "undefined" &&
    css`
      max-width: ${width}px;
    `}
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
`;

const Blogi: React.FC<{ data: any }> = ({ data }) => {
  const path = "/blog/";
  const lang = "en";
  const sitemap = useSitemap();

  return (
    <>
      <Trigger name={`pageview ${path}`} />
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://www.datocms-assets.com" />
        <link rel="canonical" href={`https://framian.fi${path}`} />
      </Helmet>
      <SEO path={path} lang={lang} title={"Blog"} metaDescription={"Blog"} />

      {data.allDatoCmsBlog.edges.map(({ node }) => (
        <div
          css={`
            background-image: url(${encodeURI(node.heroImage.url)});
            background-position: 50% 50%;
            background-size: cover;
          `}
        >
          <Container>
            <div
              css={`
                display: flex;
                justify-content: flex-start;
                align-items: center;
                min-height: 100vh;
                padding: 30px;
              `}
            >
              <Link
                to={sitemap.find(v => v.id === node.id).path}
                css={`
                  transition: transform 150ms ease-out;
                  > div {
                    transition: box-shadow 150ms ease-out;
                  }
                  &:hover {
                    transform: translateY(-3px);
                    margin-top: 3px;
                    padding-bottom: 3px;
                    > div {
                      box-shadow: 2px 5px 16px rgba(0, 0, 0, 0.35);
                    }
                  }
                `}
              >
                <div
                  css={`
                    color: #000;
                    background: #fff;
                    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.125);
                    ${node.blackOrWhiteBox &&
                    css`
                      color: #fff;
                      background: #000;
                      box-shadow: 0px 0px 32px rgba(255, 255, 255, 0.125);
                    `}
                    padding: 32px 48px;
                    width: 100%;
                    max-width: 608px;
                    > h1 {
                      font-weight: 300;
                      font-size: 42px;
                      line-height: 52px;
                      margin-bottom: 1em;
                    }
                    > p {
                      font-weight: 300;
                      font-size: 18px;
                      line-height: 28px;
                      margin-bottom: 1em;
                      white-space: pre-wrap;
                    }
                    @media (max-width: 768px) {
                      h1 {
                        font-size: 1.75rem;
                        line-height: 1.25;
                      }
                      p {
                        font-size: 1.25rem;
                        line-height: 1.25;
                      }
                    }
                  `}
                >
                  <h1>{node.title}</h1>
                  <p>{node.excerpt}</p>
                  {node.writer !== null && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 30,
                      }}
                    >
                      <div>
                        <div
                          style={{
                            background: "#c4c4c4",
                            width: 72,
                            height: 72,
                            marginRight: 24,
                            borderRadius: 36,
                            overflow: "hidden",
                          }}
                        >
                          <Img
                            fluid={node.writer.personImage.fluid}
                            alt={node.writer.personImage.alt}
                            title={node.writer.personImage.title}
                          />
                        </div>
                      </div>
                      <div style={{ flex: "1" }}>
                        <p
                          style={{
                            fontFamily: "'Roboto Mono', monospace",
                            fontSize: 16,
                            lineHeight: "24px",
                          }}
                        >
                          {node.writer.personName}
                          <br />
                          {node.writer.personTitleCompany}
                        </p>
                      </div>
                    </div>
                  )}
                  <span
                    style={{
                      fontFamily: "'Roboto Mono', monospace",
                      fontSize: 16,
                      lineHeight: "19px",
                      color: node.blackOrWhiteBox ? "#fff" : "#000",
                      float: "right",
                    }}
                  >
                    {node.publishedOn}
                  </span>
                  <Link
                    to={sitemap.find(v => v.id === node.id).path}
                    style={{
                      fontFamily: "'Roboto Mono', monospace",
                      fontSize: 16,
                      lineHeight: "19px",
                      color: node.blackOrWhiteBox ? "#fff" : "#000",
                    }}
                  >
                    _lue
                  </Link>
                </div>
              </Link>
            </div>
          </Container>
        </div>
      ))}
      <Footer path={path} />
      <NormalizeCSS />
      <GlobalStyles />
      <Favicon />
      <Menu path={path} />
      <GlobalStyles />
    </>
  );
};

export default Blogi;

export const query = graphql`
  query {
    allDatoCmsBlog(
      filter: { locale: { eq: "fi" }, showInEnBlogIndex: { eq: true } }
      sort: { order: DESC, fields: publishedOn }
    ) {
      edges {
        node {
          publishedOn(formatString: "d.M.YYYY")
          smallExcerpt
          title
          blackOrWhiteBox
          id
          heroImage {
            url
          }
          writer {
            personImage {
              fluid {
                ...GatsbyDatoCmsFluid_tracedSVG
              }
              alt
              title
            }
            personName
            personTitleCompany
            twitterHandle
          }
        }
      }
    }
  }
`;
